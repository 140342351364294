export const CustomPage = {
  ContentSliceTypes: {
    QUOTE: "PrismicCustomPageDataBodyQuote",
    TEXT: "PrismicCustomPageDataBodyText",
    CALLOUT: "PrismicCustomPageDataBodyCallout",
    TEXT_WITH_QUOTE: "PrismicCustomPageDataBodyTextWithQuote",
    IMAGES: "PrismicCustomPageDataBodyImages",
    IMAGES_WITH_QUOTE: "PrismicCustomPageDataBodyImagesWithQuote",
    IMAGE_GALLERY: "PrismicCustomPageDataBodyImageGallery",
    DIVIDER: "PrismicCustomPageDataBodyDivider",
  },
}
