/** @jsx jsx */

import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { Layout } from "../components/Layout"
import { extractBaseSeoFields } from "../utils/seoUtils"
import { CustomPage } from "../enums/CustomPage"
import { ArticlePropTypes } from "../utils/propTypes"
import { ArticleTextWithQuote } from "../components/article/ArticleTextWithQuote"
import { ArticleText } from "../components/article/ArticleText"
import { ArticleCallout } from "../components/article/ArticleCallout"
import { ArticleImages } from "../components/article/ArticleImages"
import { ArticleDivider } from "../components/article/ArticleDivider"
import { ArticleImagesWithQuote } from "../components/article/ArticleImagesWithQuote"
import { ArticleQuote } from "../components/article/ArticleQuote"
import { ArticleImageGallery } from "../components/article/ArticleImageGallery"
import { Masthead } from '../components/Masthead';
import { MediaMatcher } from '../components/MediaMatcher';

const renderContentSlice = (content_slice, key) => {
  const props = { key, ...content_slice }
  switch (content_slice.__typename) {
    case CustomPage.ContentSliceTypes.DIVIDER:
      return <ArticleDivider {...props} />
    case CustomPage.ContentSliceTypes.QUOTE:
      return <ArticleQuote {...props} />
    case CustomPage.ContentSliceTypes.TEXT:
      return <ArticleText {...props} />
    case CustomPage.ContentSliceTypes.TEXT_WITH_QUOTE:
      return <ArticleTextWithQuote {...props} />
    case CustomPage.ContentSliceTypes.CALLOUT:
      return <ArticleCallout {...props} />
    case CustomPage.ContentSliceTypes.IMAGES:
      return <ArticleImages {...props} />
    case CustomPage.ContentSliceTypes.IMAGES_WITH_QUOTE:
      return <ArticleImagesWithQuote {...props} />
    case CustomPage.ContentSliceTypes.IMAGE_GALLERY:
      return <ArticleImageGallery {...props} />
    default:
      return null
  }
}
export const CustomPageTemplate = ({ data }) => {
  const { uid, type, data: customPageData } = data.prismicCustomPage
  const seoProps = {
    ...extractBaseSeoFields({
      uid,
      type,
      ...customPageData,
    }),
  }

  const isMobile = MediaMatcher.useMedia({
    mobilePortrait: true,
    tablet: false,
  })

  return (
    <Layout seoProps={seoProps}>
      <Masthead
        heading={customPageData.title.text}
        headingStyles={{
          color: customPageData.title_color,
          mt: ["50px", "50px", 0],
        }}
        showDivider={isMobile}
        image={customPageData.masthead_background}
        containerStyles={{
          height: ["200px", "200px", "300px", "300px"],
        }}
      />
      {customPageData.body.map((content_slice, i) =>
        renderContentSlice(content_slice, i)
      )}
    </Layout>
  )
}

CustomPageTemplate.propTypes = {
  data: PropTypes.shape({
    prismicCustomPage: PropTypes.shape(ArticlePropTypes).isRequired,
  }).isRequired,
}
