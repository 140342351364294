/** @jsx jsx */

import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { CustomPageTemplate } from "./CustomPageTemplate"
import linkResolver from "../utils/linkResolver"

export const query = graphql`
  query CustomPageQuery($id: String) {
    prismicCustomPage(id: { eq: $id }) {
      _previewable
      ...CustomPage
    }
  }
`

export default withPrismicPreview(CustomPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])